<template>
    <div v-show="item.fields" :id="short_id" class="expert-row d-flex align-items-start flex-grow-1" :class="{'py-4 w-100': !in_popup}">
        <div class="d-flex flex-column" :class="{'ps-4': !in_popup}">
            <div class="">
                <img class="profile-pic" :src="scholar_details.url_picture??require('../../assets/img/avatar_scholar_256.png')"/>
            </div>
            <div class="expert-links show_expert_profile">
                <a class="me-0" target="_blank" @click="$store.dispatch('create_topics_modal', item)"><i class="fa fa-fingerprint me-1"></i>Expertise</a>
            </div>
        </div>
        <div class="d-flex flex-column expert-header px-3 flex-grow-1">
          <div class="d-flex flex-column expert-textinfo flex-column justify-content-between">
            <ExpertTitle :item="item" :scholar_details="scholar_details" :max_paper_count="max_paper_count??authors_best_work_count" :orcid_details="orcid_details"></ExpertTitle>
            <div class="d-flex flex-row expert-links flex-wrap mt-1">
              <!-- <span class="me-1">Matches:</span> -->
              <a class="me-1" :key="item.id+topic.id" v-for="topic in top_topics.slice(0,3)">{{topic.display_name}}</a>
            </div>
            <ExpertLinks :item="item" :scholar_details="scholar_details" :orcid_details="orcid_details"></ExpertLinks>
            <CollectionItems :item="item" :pubs="item.collection?.records" :in_popup="in_popup"></CollectionItems>
          </div>
        </div>
        <div class="hide_result_item position_end user-select-none" 
             v-if="filter_available('excluded_records') && !in_graph" 
             @click="hide_result_item(item)">
          <i class="fa fa-xmark"></i>
        </div>
    </div>
</template>

<script>
import LabelBox from "../LabelBox.vue";
import ExpertTitle from "./ExpertTitle.vue";
import ExpertLinks from "./ExpertLinks.vue";
import CollectionItems from "../CollectionItems.vue";
import shared from "../shared.js"

export default {
  name: "Expert",
  props: ["item", "max_paper_count", "in_popup", "in_graph"],
  components: {
    LabelBox,
    ExpertTitle,
    ExpertLinks,
    CollectionItems,
  },
  computed: {
    short_id: function () {
      return this.item.fields.id.replace("https://openalex.org/", "");
    },
    graph_width: function () {
      if (this.$store.state.show.sidebar == "graph") return 150;
      else return 300;
    },
    authors_best_work_count: function() {
      return _.maxBy(this.item.fields.counts_by_year, 'works_count')['works_count']
    },
    top_topics: function() {
      let _this = this;
      return []
    }
  },
  data: function () {
    return {
      scholar_details: { url_picture: null, success: false },
      selected_work: {
        title: "",
        abstract: "",
      },
      requests: {},
      orcid_details: {
        retrieved: false,
        person: { "researcher-urls": { "researcher-url": [] } },
      },
      getGoogleScholar: 1,
    };
  },
  mounted: function () {
    let _this = this;
    if (
      this.getGoogleScholar &&
      this.$store.state.selected_searchmodule.allowed_api_endpoints.includes(
        "find_scholar"
      )
    ) {
      if (
        Object.keys(this.$store.state.apicache).includes(
          "google_scholar/" + this.item.fields.id
        )
      ) {
        this.scholar_details =
          this.$store.state.apicache[
            "google_scholar/" + this.item.fields.id
          ];
      } else {
        this.requests.scholar = new AbortController();
        let institution = shared.get_lki(this.item);
        this.$axiosQ.addToQueue('gcapi', {
            method: "post", 
            url: "/api/find_scholar/",
            data: {
              display_name: this.item.fields["display_name"],
              institution: institution?.display_name ?? null,
              search_module: this.$store.state.selected_searchmodule.pk,
            },
            signal: this.requests.scholar.signal,
          }, 0).then(
            (response) => {
              console.log(response.data);
              let google_scholar_reponse = response.data
              google_scholar_reponse['success'] = true
              this.scholar_details = google_scholar_reponse;
              this.$store.commit("ADD_TO_APICACHE", {
                endpoint: "google_scholar",
                id: this.item.fields.id,
                response: google_scholar_reponse,
              });
            },
            (response) => {
              // probably 404
              this.$store.commit("ADD_TO_APICACHE", {
                endpoint: "google_scholar",
                id: this.item.fields.id,
                response: this.scholar_details,
              });
            }
          );
      }
    }
    if (
      typeof this.item.fields.orcid !== "undefined" &&
      this.item.fields.orcid !== null &&
      this.orcid_details.retrieved == false
    ) {
      if (
        Object.keys(this.$store.state.apicache).includes(
          "orcid/" + this.item.fields.id
        )
      ) {
        this.orcid_details =
          this.$store.state.apicache["orcid/" + this.item.fields.id];
      } else {
        this.requests.orcid = new AbortController();
        this.$axiosQ.addToQueue('orcid', {
            method: "get", 
            url: "https://pub.orcid.org/v3.0/" +
              _this.item.fields.orcid.replace("https://orcid.org/", "") +
              "/record",
            headers: { Accept: "application/json" },
            signal: this.requests.orcid.signal,
          }, 1)
          .then(
            (response) => {
              _this.orcid_details = response.data;
              _this.orcid_details.retrieved = true;
              this.$store.commit("ADD_TO_APICACHE", {
                endpoint: "orcid",
                id: this.item.fields.id,
                response: response.data,
              });
            },
            (response) => {
              this.$store.commit("ADD_TO_APICACHE", {
                endpoint: "orcid",
                id: this.item.fields.id,
                response: this.orcid_details,
              });
            }
          );
      }
    }
  },
  beforeUnmount() {
    console.log(
      "about to destroy an Author element, cancelling the associated requests"
    );
    _.each(this.requests, function (request, num) {
      if (typeof request.abort === "function") {
        request.abort();
      }
    });
  },
  methods: {
    get_concepts: function () {
      return _.map(this.item.fields.x_concepts, "display_name").join("; ");
    },

    // Hiding results is done by adding it to a list of Excluded Records:
    hide_result_item: function(record) {
      this.$store.commit('ADD_TO_INEX_COLLECTION', {
        record_to_add: record,
        record_model: 'excluded_record'
      })
    },
    hover: function (active) {
      if (
        this.$store.state.graph["graph_data"] &&
        this.$store.state.graph["graph_data"]["nodes"]
          .map(function (item) {
            return item.id;
          })
          .includes(this.short_id)
      ) {
        this.$store.commit("SET_GRAPH_HOVER", {
          node: this.short_id,
          hover: active,
        });
        if (this.$store.state.show.sidebar == "graph") {
          this.$store.commit("REFRESH_RENDERER");
        }
      }
    },
    toggle_details: function () {
      this.show.description = 1 - parseInt(this.show.description);
    },
    filter_available: function(field_name) {
      return this.$store.getters.getCurrentlyAvailableFilters.includes(field_name)
    }
  },
};
</script>

<style>
@media (max-width: 992px) {
  .expert-row {
    flex-direction:column;
    max-width:100vw;
  }
  .expert-action-container {
    display:none;
  }
}
.expert-action-container {
  align-items:center;
  opacity:0;
}
.expert-row:hover .expert-action-container {
  opacity:1;
  white-space: nowrap;
}

.profile-pic {
  width: 90px;
  height: 90px;
  border-radius: var(--ms-radius);
  object-fit: cover;
  border: 2px solid #E6E6E6;
}
.work_link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.slide-leave-active {
  transition: all 0.5s ease-out;
  opacity: 1;
}
.slide-enter-active {
  transition: all 0.5s ease-out;
  opacity: 1;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translate(0, -30px);
}
.slide-enter-to,
.slide-leave-from {
  opacity: 1;
}
.slide-move {
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.email_popper_box {
  width: auto !important;
}
.email_popper_box a {
  color: var(--primary_color) !important;
}
.clipped {
  min-width: 0;
  display: block;
  max-height: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expanded {
  max-height: 700px !important;
  overflow-y:hidden !important;
}
em {
  font-weight: 800;
  font-style: normal;
}
.show_expert_profile {
  text-align: center;
}
.position_end {
    position:relative;
    right:24px;
    top:0;
}

.hide_result_item {
    color:var(--grey_text);
    cursor:pointer;
}
.hide_result_item:hover {
    color:var(--primary_color);
}
</style>