<template>
    <div class="d-flex flex-column flex-grow-1">
        <ResultlistTopbar :sortable="sortable"/>
        <NetworkGraph v-if="$store.getters.getResultVisible && this.show.results == 'as_graph'"/>
        <TopicsChart v-if="$store.getters.getResultVisible && this.show.results == 'as_topics'"/>
        <div class="d-flex" v-if="$store.getters.getResultVisible && this.show.results == 'as_list'">
            <div id="expert_results" class="d-flex flex-column flex-grow-1">
                <div v-if="$store.state.searchbox_collection.records[0].record_type == 'author'">
                    <QueryExpert
                        :display_name="$store.state.searchbox_collection.records[0].fields.display_name"
                        :identifier="$store.state.searchbox_collection.records[0].identifier"
                        :max_paper_count="max_paper_count" />
                </div>
                <div class="result-row" :key="item.fields.id" v-for="item in $store.getters.getCurrentResultList">
                    <Expert :key="'exp_'+item.fields.id" 
                            v-if="item.fields.display_name &&
                                  item.identifier !== $store.state.searchbox_collection.records[0].identifier"
                            :item="item"
                            :max_paper_count="max_paper_count"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from "vuex";
import QueryExpert from './QueryExpert.vue'
import Expert from './Expert.vue'
import ResultlistTopbar from '../ResultlistTopbar.vue'
import NetworkGraph from '../Graph.vue'
import TopicsChart from '../TopicsChart.vue'

export default {
    name: "Experts",
    components: {Expert, QueryExpert, ResultlistTopbar, NetworkGraph, TopicsChart},
    computed: {
        ...mapState(['show']),
        max_paper_count: function() {
            if(typeof(this.$store.getters.getCurrentResultList) === 'undefined' || !this.$store.getters.getCurrentResultList.length) return (0);
            let count_per_expert = []
            this.$store.getters.getCurrentResultList.map(function(expert) {
                if(!expert.fields.counts_by_year) {return true;}
                count_per_expert.push(expert.fields.counts_by_year.map(function(years) {return years.works_count}));
            });
            let max_count = count_per_expert.flat().sort(function(a, b) {
                return a - b;
            }).slice(-4, -1)[0]
            return max_count
        },
        sortable:function() {
            let sortable_items = [
                {
                    "name" : "Relevance",
                    "field": "fields.ranking_score",
                    "default_order": "desc"
                },
                {
                    "name" : "Name",
                    "field": "fields.display_name",
                    "default_order": "asc"
                },
                {
                    "name" : "Work count",
                    "field": "fields.works_count",
                    "default_order": "desc"
                },
            ]
            if(this.$store.state.selected_searchmodule.allowed_api_endpoints.includes('trad_metrics')) {
                sortable_items.push({
                    "name" : "H Index",
                    "field": "fields.summary_stats.h_index",
                    "default_order": "desc"
                })
            }
            if(this.$store.getters.getCurrentlyAvailableFilters.includes('coi_authors') && this.$store.getters.getAuthorsWithCoI.length) {
                sortable_items.push({
                    "name" : "Conflict",
                    "field": "fields.conflict_of_interest",
                    "default_order": "desc"
                })
            }
            return sortable_items
        }
    }
}
</script>

<style>
.btn {
    padding: 0.25rem 0.5rem;
}
.author_title {
    font-weight:700;
}
.selectize_flex {
    min-width:50%;
    flex: 1;
}
</style>