<template>
    <div class="p-absolute">
        <div class="d-flex" id="sidebar-container" :class="{
                'hidden':!$store.getters.getResultVisible,
                'sidebar_open':$store.state.show.sidebar, 
                'sticky': !overflowing
            }">
            <div id="result-sidebar" class="d-flex flex-column sidebar-content" ref="sidebar_container" :class="{'hidden': !sidebar_available}">
                <div class="d-flex justify-content-between align-items-center">
                    <div v-if="filters_available" role="button" 
                        @click="$store.commit('SET_SIDEBAR', 'filters')"
                        class="tab left p-2" 
                        :class="$store.state.show.sidebar === 'filters'?'active':''" 
                        href=""> Filters
                    </div>
                    <div v-if="collection_available"
                        role="button" 
                        @click="$store.commit('SET_SIDEBAR', 'collection')"
                        class="d-flex align-items-center tab right p-2" 
                        :class="$store.state.show.sidebar === 'collection'?'active':''"
                        id="show-collection-button">
                            Collection
                            <span class="badge" :class="{'transparent': !$store.state.search.uuid && !$store.getters.countRecordsInSearch}" id="collection-counter">{{$store.state.search.included_records.length}}</span>
                    </div>
                    <span id="collectable"><i class="fa fa-file-text"></i></span>
                </div>
                <div id="sidebar-parent" :class="{'collapsed': show.sidebar_collapse}">
                    <div key='filters' class="p-3" id="filter_sidebar" v-show="$store.state.show.sidebar === 'filters'">
                        <filters v-if="selected_searchmodule.pk"></filters>
                        <div class="d-flex flex-column" v-if="$store.state.user_profile.organisation.name == 'AcademicTransfer'" style="width:200px;">
                            <a href="https://academictransfer.com" target="_blank" class="mt-4">
                                <img id="at_logo" class="w-100" src="../../assets/img/at_logo.svg"/>
                            </a>
                            <p class="text-center">Add filters and search smarter in <a target="_blank" href="/app/upgrade/">an upgraded license.</a></p>
                        </div>
                    </div>
                    <collection class="mt-3" id="collection_sidebar" key='collection-bar' v-show="$store.state.show.sidebar === 'collection'"></collection>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import filters from './Filters/Filters.vue'
import collection from './Collection.vue'
import Graph from '../Graph.vue'

export default {
    name: "sidebar",
    components: {filters, collection, Graph},
    computed: {
        ...mapState(['searchmodules', 'selected_searchmodule', 'user_profile', 'show']),
        sidebar_available() {
            return (this.filters_available || this.collection_available)
        },
        filters_available() {
            return this.$store.state.selected_searchmodule.filterable_fields[0] !== ''  || this._.components[this.$store.state.selected_searchmodule.display_name+'Options']
        },
        collection_available() {
         return this.$store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')    
        }

    },
    data: function() {return({
        overflowing:0,
    })},
    methods: {
        check_active_source: function(searchmodule) {
            if (typeof(this.selected_searchmodule) !== 'undefined' && this.selected_searchmodule.pk === searchmodule.pk) {
                return 'searchmodule-active'
            }
        }
    },
    mounted: function() {
        const sidebar_filters = document.getElementById('result-sidebar')
        let _this = this;
        const overflowObserver = new ResizeObserver(function(fixed_heights) {
            var fixed_heights = 184 // 46 + 81 + 33 + 24 // topnav + search bar + tabs + tab margin
            _this.overflowing =  ( sidebar_filters.offsetHeight  + fixed_heights )> window.innerHeight
        });
        overflowObserver.observe(sidebar_filters)
    },
    onBeforeUnmount:function() {
        const sidebar_filters = document.getElementById('result-sidebar')
        overflowObserver.unobserve(sidebar_filters)
    }
}   
</script>

<style>
#sidebar-container {
    margin-top: 68px;
    margin-right:1rem;
    background: rgba(255, 255, 255, 0.5);
    top:calc(var(--searchbar_height) + var(--navbar_height) + 24px);
    box-shadow: var(--box_shadow);
}
/* MOBILE */
@media (max-width: 992px) {
    #sidebar-container {
        margin-top:.5rem;
        margin-right:0rem;
        top:0;    
    }
    #result-sidebar {
        width:100%;
    }
    #sidebar-parent.collapsed {
        max-height:0;
        overflow-y:hidden;
        margin:0;
    }
}
#result-sidebar {
    border-radius:var(--ms-radius);
}
#result-sidebar .selectize-input .item {
    max-height:1.4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width:calc(var(--sidebar_width) - 20px);
    left:calc(50px + .75rem);
}
#sidebar-container.hidden {
    height:0!important;
}
.sticky {
    position:sticky!important;
}
.sidebar_open {
    max-width:100%!important;
}
.tab {
    border-bottom: 1px solid transparent;
    flex-grow:1;
    overflow:hidden;
    min-width:50%;
}
.tab.left {
    justify-content:center;
    text-align:center;
    border-radius: var(--ms-radius) 0 0 0;
}
.tab.right {
    border-radius: 0 var(--ms-radius) 0 0;
    justify-content:center;
}
.tab:hover {
    background-color:var(--primary_color);
    color:white;
}
.tab.active {
    font-weight:700;
    background-color:var(--primary_color);
    color:white;
}
#collection-counter {
    margin: -.75rem 0 0 .25rem;
    font-size:.65rem;
    background-color: var(--orange_highlight);
}
#collection-counter.transparent {
    width:0px;
    padding:0;
    margin:0;
    opacity:0;
}
#collectable {
  position:fixed;
  opacity:0;
  height:10px;
  width:10px;
  color:var(--orange_highlight);
}
.tab svg {
    color: var(--primary_color);
}
.tab.active svg {
    color: var(--orange_highlight);
}
#result-sidebar.hidden {
    display:none!important;
}
#sidebar_transition_container {
    width:400px;
    display:flex;
}
.sidebar-content {
    width:var(--sidebar_width);
}
.sidebar-child {
    padding: 1rem;
}
.button_label {
    display:none;
    opacity:0;
    width:0;
}
.sidebar-title {
    margin: .5rem 0;
}
.sidebar-title, .ellipsis-text {
    text-align:left;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
</style>