<template>
  <div id="resultlist-topbar" class="d-flex justify-content-between pt-2 align-items-baseline">
    <div class="d-flex">
        <ExcelExport v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('use_collections')"
            :exporting_items="grouped_current_results"
            classes="top-tab"
            :icon="true"
            title="Export all results to Excel"
            label="Export to Excel">
        </ExcelExport>
        <span class="top-tab"
            id="toggle_list" 
            title="Show List" @click="$store.state.show.results = 'as_list'">
                Show List
                <i class="fa fa-list ms-2"></i>
        </span>
        <span class="top-tab" 
            id="toggle_network_graph" 
            title="Show network graph" @click="$store.state.show.results = 'as_graph'" 
            v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('network_graph')">
                Show network graph
                <i class="fa fa-diagram-project ms-2"></i>
        </span>
        <span class="top-tab"
            id="toggle_topics" 
            title="Show topics" @click="$store.state.show.results = 'as_topics'" 
            v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('topics')">
                Show Topics
                <i class="fa fa-fingerprint ms-2"></i>
        </span>
    </div>
    <div class="d-flex align-items-center">
        <SearchStats/>
        <div v-if="sortable?.length">
            <div id="active-sort" class="btn-primary active" v-if="show.results=='as_list'" @click="toggle_sort_dropdown()">
                {{active_sort.name}} 
                <span class="ms-1" :class="active_sort.order=='desc'?'flip-y':''"><i class="fa fa-sort-asc"></i></span>
            </div>
            <div v-show="show.dropdown == 'sort_options'" class="dropdown-menu show">
                <span role="button" class="dropdown-item" :key="'sort'+sort_option.name" @click="set_sort(sort_option)" v-for="sort_option in sortable">
                    {{sort_option.name}}
                </span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ExcelExport from './ExcelExport.vue'
import SearchStats from './SearchStats.vue'
import { mapState } from 'vuex'

export default {
    name: 'ResultlistTopbar',
    components: {ExcelExport, SearchStats},
    computed: {
        ...mapState(["show", "active_sort"]),
        grouped_current_results: function() {
            return _.groupBy(this.$store.getters.getCurrentResultList, function(item) {
                console.log(item)
                return item.record_type + 's'
            })
        }
    },
    data: function() {return({
    })},
    methods: {
        set_sort: function(sort_option) {
            this.$store.commit('SORT_RESULT_LIST', sort_option)
        },
        toggle_sort_dropdown: function() {
            if (this.show.dropdown == 'sort_options') {
                this.$store.commit('SET_DROPDOWN', null)
            }
            else {
                this.$store.commit('SET_DROPDOWN', 'sort_options')
            }
        }
    },
    mounted: function() {
        console.log(this.sortable)
        if (!_.map(this.sortable, 'field').includes(this.active_sort.field)) {
            this.$store.commit('SORT_RESULT_LIST', this.sortable[0])
        }
    },
    props: ['sortable'],
}
</script>

<style scoped>
.dropdown-menu {
    display:block;
    z-index:6
}
#active-sort span svg {
    transition: transform ease var(--fast_anim);
}
#active-sort span.flip-y svg {
    transform: scaleY(-1);
}
#resultlist-topbar {
    top:0px;
    z-index:5;
}
.top-tab {
    border-radius: var(--ms-radius) var(--ms-radius) 0 0;
    cursor:pointer;
    padding: .25rem 2rem;
    border-color: var(--grey_divider);
    color: var(--grey_text);
    background:transparent;
    box-shadow: 0px -3px 6px #0000000D;
}
.top-tab:hover {
    color:var(--primary_color);
}
</style>