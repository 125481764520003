<template>
    <div class="d-flex flex-column align-items-start justify-content-between">
        <div class="top-row d-flex justify-content-start mb-2">
            <div class="expert-name me-2" :class="check_graph_hover">{{item.fields.display_name}} 
                <small v-show="conflict_of_interest.flag"
                        :id="'coi_anchor_'+item.fields.id"
                        class="coi-warning ms-2 badge bg-danger"
                        @mouseenter="set_show_coipopper(1)"
                        @mouseleave="set_show_coipopper(0)"
                        >{{conflict_of_interest.short_label}}</small>
            </div>
            <div class="expert-affiliation d-flex me-2">
                <span class="clipped me-1" :title="scholar_details.affiliation??parse_affiliation.display_name">
                    {{scholar_details.affiliation??parse_affiliation.display_name}}
                </span>
                <span>({{parse_affiliation.country_code}})</span>
            </div>
            <div class="expert-action-container">
                <div class="d-flex" v-if="this.$store.state.selected_searchmodule.allowed_api_endpoints.includes('get_author_abstracts')">
                    <GreyDivider margin_class='mx-2'/>
                    <span class="expert-action mx-2" 
                          @click="$store.dispatch('more_like_expert', item)"
                          :title="'Find more experts like ' + item.fields.display_name + ' (in new tab)'"
                          >More like {{item.fields.display_name.split(' ')[0]}}
                    </span>
                </div>
                <div class="d-flex" v-if=" $store.state.selected_searchmodule.allowed_api_endpoints.includes('find_email')">
                    <GreyDivider margin_class='mx-2'/>
                    <a :id="'email_addrs'+short_id" 
                        class="expert-action emphasis mx-2" role="button" 
                        :title="'Find email addresses for '+item.fields.display_name+'.'" 
                        @click="get_email_addresses($event)">Send mail to {{item.fields.display_name.split(' ')[0]}}
                    </a>
                </div>
            </div>
        </div>
        <div class="d-flex bottom-row mb-2">
            <span class="grey-text text-nowrap">Total Publications: <strong>{{item.fields.works_count}}</strong></span>
            <GreyDivider v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('trad_metrics')" margin_class='mx-4'/>
            <span v-if="$store.state.selected_searchmodule.allowed_api_endpoints.includes('trad_metrics')">H-index {{item.fields.summary_stats?.h_index??'-'}}</span>
            <GreyDivider margin_class='mx-4'/>
            <span class="graph d-flex">
                <span class="graph-year">{{get_datapoints['first_pub_year']}}</span>
                <VueBarGraph
                    class="d-block"
                    :points="get_datapoints['points']"
                    :customLabels="get_datapoints['last_years_short']"
                    :use-custom-labels="true"
                    :barColor="'var(--primary_color)'"
                    :maxYAxis="max_paper_count"
                    :show-x-axis="false" 
                    :show-y-axis="false" 
                    :label-height="0"
                    :show-values="false" 
                    :height="20"
                    :width="120"
                />
                <span class="graph-year">{{get_datapoints['last_pub_year']}}</span>
            </span>
            <div class="expert-action-container">
                <GreyDivider margin_class='mx-4'/>
                <span class="expert-action" 
                    :title="'Check co-authorships for '+item.fields.display_name+'.'" 
                    @click="$store.dispatch('create_coauthorship_modal', item)">Check co-authors
                </span>
            </div>
        </div>
        <!-- popovers  -->
        <div :id="'coi_tooltip_'+item.fields.id"
            class="coi_popover"
            v-show="show.coi_popper"
            v-html="conflict_of_interest.label">
        </div>
        <div v-show="show.email_popover" :id="'email_popper_box'+short_id" class="popup-details email_popper_box">
            <div class="float-end ms-4 close-button close-button-work" @click="show.email_popover=0"><i class="fa fa-close"></i></div>
            <h6>Public email address for {{item.fields.display_name}}</h6>
            <span v-if="item.fields.email_address === 'Not found'">We were unable to find an e-mail address for {{item.fields.display_name}}</span>
            <div v-else :key="'emailaddr'+idx" v-for="(email_address, idx) in item.fields.email_candidates">
                <span>
                    <a class="email_candidate" :href="'mailto:'+email_address['email_address']"><i class="fa fa-envelope"></i> {{email_address.email_address}}</a> 
                    found <a target="_blank" :href="email_address['source']">{{email_address['source_description']}}</a> 
                    at date {{email_address['source_timestamp']??'unknown'}}.</span>
                
            </div>
            <div v-show="show.loading_emails"><i class="fa fa-spinner fa-spin"></i></div>
            <div v-show="show.no_emails_found"><i class="fa fa-warning"></i> Apologies, we could not find an email address for {{item.fields.display_name}}</div>
        </div>
    </div>
</template>

<script>
import VueBarGraph from 'vue-bar-graph';
import ExpertLinks from "./ExpertLinks.vue";
import GreyDivider from "../GreyDivider.vue";
import { createPopper } from "@popperjs/core";
import shared from "../shared.js"

export default {
    name: 'ExpertTitle',
    props: ['item', 'scholar_details', 'orcid_details', "max_paper_count"],
    components: {VueBarGraph, ExpertLinks, GreyDivider},
    computed: {
        conflict_of_interest: function () {
            let coi = {
                flag: false,
                label: "",
                short_label:""
            }
            let coi_data = this.$store.state.coi_relationships[this.item.identifier]
            if(!coi_data) {
                return coi
            } 
            else if (coi_data.intersection) {
                coi.flag = true
                coi.label = "Author is part of Conflict of Interest check."
                coi.short_label = "Conflict"
                return coi
            }
            else if (coi_data.conflicts.coauthors.conflict) {
                coi.flag = true
                coi.short_label = "Potential conflict"
                coi.label += "Has co-authored with <strong>" + coi_data.conflicts.coauthors.evidence.length + "</strong> author(s) from the Conflict of Interest check. "
            }
            else if (coi_data.conflicts.coaffiliated.conflict) {
                coi.flag = true
                coi.short_label = "Potential conflict"
                coi.label += "Shares (historical) affiliations with <strong>"+coi_data.conflicts.coaffiliated.evidence.length+"</strong> author(s) from the Conflict of Interest check."
            }
            this.item.fields.conflict_of_interest = coi.flag
            return coi
        },
        parse_affiliation: function() {
            const lki = shared.get_lki(this.item);
            if (lki && lki.display_name && lki.country_code) {
                return lki
            } else {
                return {
                    display_name: "Affiliation unknown",
                    country_code: ""
                }
            } 
        },
        get_datapoints:function() {
            let current_year = new Date().getFullYear()
            let last_years = Array.from({length: 12}, (_, i) => current_year - i).reverse()
            let last_years_short = Array.from({length: 12}, (_, i) => (current_year - 2000 - i)).reverse()
            let points = Array(12).fill(0)
            _.each(this.item.fields.counts_by_year, function(item) {
                if (item.works_count > 0 && last_years.indexOf(item.year) > -1) {
                    points[last_years.indexOf(item.year)] = item.works_count;
                }
            });
            return {
                'first_pub_year': _.minBy(this.item.fields.counts_by_year, 'year')?.year,
                'last_pub_year': _.maxBy(this.item.fields.counts_by_year, 'year')?.year,
                'points': points, 
                'last_years': last_years, 
                'last_years_short': last_years_short}
            },
        short_id: function () {
            return this.item.fields.id.replace("https://openalex.org/", "");
        },
    },
    data: function() {return({
        show: {
            coi_popper: 0,
            email_popover: 0,
            loading_emails: 0,
            no_emails_found: 0,
        },
        popper_objects: {
            coi: {}
            }
        })
    },
    methods: {
        check_graph_hover: function () {
            if (this.$store.state.graph["hover"] == this.short_id) {
                return "active";
            }
        },
        set_show_coipopper(show_bool) {
            console.log('setting COI popper to ' + show_bool)
            this.show.coi_popper = show_bool;
            this.popper_objects.coi.update()
        },
        get_email_addresses: async function ($event) {
            var _this = this;
            this.show.email_popover = 1;
            const anchor = $event.target;
            const tooltip = document.querySelector(
                "#email_popper_box" + this.short_id
            );
            createPopper(anchor, tooltip, {
                placement: "right",
                modifiers: [
                {
                    name: "offset",
                    options: {
                    offset: [16, 8],
                    },
                },
                ],
            });
            if(this.show.no_emails_found || this.item.fields.email_address) {
                return true
            }
            else {
                this.show.loading_emails = 1;
                _paq.push([
                    "trackEvent",
                    "find_expert_email",
                    this.item.fields.display_name,
                    this.short_id,
                ]);
                await this.$store.dispatch('find_email_addresses', _this.item)
                this.show.loading_emails = 0
                if (!_this.item.fields.email_candidates.length) {
                    _this.show.no_emails_found = 1;
                }
            }
        },
    },
    mounted:function() {
        let anchor = document.getElementById("coi_anchor_"+this.item.fields.id)
        let tooltip = document.getElementById("coi_tooltip_"+this.item.fields.id)
        this.popper_objects.coi = createPopper(anchor, tooltip, {
            placement: "bottom",
            modifiers: [
            {
                name: "offset",
                options: {
                offset: [0,8],
                },
            },
            ],
        });
    }
}
</script>

<style>
.expert-name {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 900;
  flex-shrink:0;
  color: var(--almost-black);
  max-width:100vw;
}
.expert-name.active {
  color: var(--orange_highlight) !important;
}
.expert-affiliation {
  color: var(--almost-black);
  font-size: 1.2rem;
  flex-shrink:2;
  font-weight: 300;
  max-width:100vw;
}

.top-row {
    align-items:center;
}
@media (max-width: 992px) {
    .top-row, .bottom-row {
        flex-direction:column;
        align-items:start;
    }
}
.expert-action {
    cursor:pointer;
    text-decoration:underline;
    color: var(--grey_text);
    flex-shrink:0;
    white-space: nowrap;
}
.expert-action.emphasis {
    color: var(--primary_color);
}
.graph-year {
    font-size:.6rem;
    font-weight:700;
    margin: 0rem .125rem;
}
.graph {
    display:flex;
    align-items:baseline;
}
.coi-warning {
  font-size: 0.7rem !important;
  cursor: pointer;
}
.coi_popover {
    display:block;
    background-color: #333!important;
    color: white;
    text-align:center;
    font-weight:500;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
}
</style>