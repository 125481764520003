<template>
  <div id="filters" class="">
    <div class="filter-row text-left">
        <FilterPresetSelector v-if="$store.state.user_profile.default_filter_preset?.uuid"/>
        <CoIFinder v-if="filter_available('coi_authors')" key="conflict_of_interest_finder"/>
        <DateRangeFilter v-if="filter_available('publication_date')" field='publication_date'/>
        <SourceTypeFilter v-if="filter_available('source_type')"/>
        <DocumentTypeFilter v-if="filter_available('document_type')"/>
        <div class="mb-3" v-show="filter_available('institution.country_code')">
            <CountryFilter :scope="$store.state.user_profile.organisation.name == 'AcademicTransfer'?'nl_only':'any'"></CountryFilter>
            <div v-if="$store.state.user_profile.organisation.nl_language && filter_available('language')" class="mt-1">
                <label class="checkbox-inline d-flex justify-content-between align-items-center" for="DutchCheckbox">
                    <div>
                        <input id="DutchCheckbox" v-model="language_nl" value="1" type="checkbox"/>
                        <span class="d-none d-xxl-inline-block ms-1">Likely</span> Dutch speaker 
                    </div>
                    <span class="grey-text"><i class="fa fa-info-circle" title="Using this checkbox includes authors who have either:
            1) published in the Dutch language,
            2) published whilst affiliated to an NL/BE institution 
            3) are currently affiliated to an NL/BE institution."></i>
                    </span>
                </label>
            </div>
        </div>
        <TextFilter v-if="filter_available('abstract')"></TextFilter>
        <div class="mb-3" v-show="filter_available('institution.type')" :title="'Select institution types'">
            <div for="institution_type" class="sidebar-title">Type of institution</div>
            <Multiselect
                mode="tags" label="name" valueProp="id" :searchable="false" placeholder="Select an institution type"
                v-model="institution_type" :object="false" id="institution_type_multiselect"
                :class="{'active' : institution_type?.length}"
                :options="institution_type_select_options"
            />
            <FilterSlider field='institution.type'/>
        </div>
        <InstitutionNameFilter v-show="filter_available('institution.id')" />
    </div>
    <component v-if="$store.state.user_profile.organisation.name != 'AcademicTransfer'" v-bind:is="$store.state.selected_searchmodule.frontend_result_component+'Options'"></component>
    <button v-if="context!=='PresetFilters'" id="filter_update" class="btn-primary float-end mb-4" title="Rerun/update search" @click="$store.dispatch('find_similar')"><i class="fa fa-refresh"></i> Update</button>
  </div>
</template>

<script>
import CountryFilter from './CountryFilter.vue'
import CoIFinder from '../CoIFinder.vue'
import SourceTypeFilter from './SourceTypeFilter.vue'
import DocumentTypeFilter from './DocumentTypeFilter.vue'
import DateRangeFilter from './DateRangeFilter.vue'
import ExpertsOptions from '../ExpertsOptions.vue'
import FilterPresetSelector from './FilterPresetSelector.vue'
import FilterSlider from './FilterSlider.vue'
import InstitutionNameFilter from './InstitutionNameFilter.vue'
import {mapState} from 'vuex'
import Multiselect from '@vueform/multiselect'
import PartnersOptions from '../../Partner/PartnersOptions.vue'
import ProjectsOptions from '../../Partner/PartnersOptions.vue'
import TextFilter from './TextFilter.vue'
export default {

    name: "Filters",
    props: ["preset", "context"],
    components: {
        Multiselect,
        CoIFinder,
        CountryFilter,
        DateRangeFilter,
        DocumentTypeFilter,
        FilterPresetSelector,
        SourceTypeFilter,
        ExpertsOptions,
        InstitutionNameFilter,
        PartnersOptions,
        ProjectsOptions, 
        DocumentTypeFilter,
        FilterSlider,
        TextFilter
    },
    computed: {
        ...mapState(['user_profile', 'selected_searchmodule']),
        institution_type: {
            get() {
                return this.$store.state.filters['institution.type']?this.$store.state.filters['institution.type']['values']:[]
            },
            set(value) {
                this.$store.dispatch('update_filter', {field_name: 'institution.type', 'values':value})
            }
        },
        language_nl: {
            get: function(){
                return Object.keys(this.$store.state.filters).includes('language');
            },
            set: function(value) {
                this.$store.commit('TOGGLE_LANGUAGE_NL')
            }
        },
    },
    data: function() {return({
        institution_type_select_options: [
                {'id': 'education', 'name': 'Education'},
                {'id': 'healthcare','name': 'Healthcare'},
                {'id': 'government','name': 'Government'},
                {'id': 'facility',  'name': 'Facility'},
                {'id': 'company',   'name': 'Company'},
                {'id': 'nonprofit', 'name': 'Non-profit'},
                {'id': 'other',     'name': 'Other'},
                {'id': 'archive',   'name': 'Archive'},
        ]
    })},
    methods: {
        filter_available: function(field_name) {
            return this.$store.getters.getCurrentlyAvailableFilters.includes(field_name)
        }
    },
}
</script>

<style>
    .filter-row {
        max-width: 100%!important;
    }
    .multiselect-clear, .multiselect-caret {
        z-index:4!important;
    }
    .multiselect-clear-icon:hover {
        background-color:var(--almost_black);
    }
    .exclude {
        color:var(--contrast_color);
    }
    .selectize-input {
        padding: 4px 8px!important;
    }
    .form-control {
        font-size:13px!important;
        padding: 4px 8px!important;
    }
    #co-author-check .ellipsis-text {
        max-width:125px;
    }
    .filter-row {
        max-width:var(--sidebar_width);
    }
    #filters .multiselect {
        box-shadow: var(--box_shadow_emphasis);
    }
    #filters .active {
        color: var(--primary_color);
    }
    #filters .multiselect.active .multiselect-caret{
        background-color: var(--primary_color);
        transform:rotate(270deg)
    }
    #filters .text-greyed-out {
        color:#999;
    }
    #filters .multiselect-placeholder {
        font-size:80%;
        padding-right:0!important;
    }
    #filters .multiselect-tags {
        justify-content: flex-start;
        max-width:100%;
        overflow-x:hidden;
    }
    #filters .multiselect-tag {
        text-overflow:ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .multiselect-dropdown {
        overflow-x:visible;
        z-index: 5!important;
        min-width:25vw;
        border: none!important;
        box-shadow:var(--box_shadow_emphasis);
    }
    .multiselect-placeholder {
        font-size: .8rem;
    }
    #filters .form-control {
        padding: 8px;
    }
    #filters .form-control:focus {
        border: var(--ms-border-width-active,var(--ms-border-width,1px)) solid var(--ms-border-color-active,var(--ms-border-color,#d1d5db));
        box-shadow: 0 0 0 var(--ms-ring-width,3px) var(--ms-ring-color,rgba(16,185,129,.188));
    } 
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
