<template>
  <div class="home" 
      :class='{
      "bg-at": $store.state.user_profile.organisation.name == "AcademicTransfer",
      "bg-gc": $store.state.user_profile.organisation.name != "AcademicTransfer",
    }'
    :style="getColorStyling()">
    <navbar></navbar>
    <div id="app-content" class="d-flex flex-column flex-grow-1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="py-4">Checking for conflicts of interest</h2>
            <p>On this page you can do batchwise comparisons between two stored <a target="_blank" href="/app/filters/">Filters</a>. The filters that contain authors in the Conflict of Interest field are selectable here.</p>
          </div>
          <div class="col-6 col-xs-12">
            <div for="institution_type" class="sidebar-title">Check these authors:</div>
            <Multiselect
                mode="single" label="name" valueProp="uuid" :searchable="true" placeholder="The authors will be placed on rows" 
                :attrs='{"autocomplete": "one-time-code"}' @change="show.results = 0"
                v-model="check_uuid" :object="false" id="check_uuid_multiselect" :closeOnSelect="true" :closeOnDeselect="true"
                :options="user_profile.filter_presets" :class="{'active' : check_uuid}"
            >
            </Multiselect>
            <div for="institution_type" class="sidebar-title">Against these authors:</div>
            <Multiselect
                mode="single" label="name" valueProp="uuid" :searchable="true" placeholder="The authors will be placed on columns" 
                :attrs='{"autocomplete": "one-time-code"}' @change="show.results = 0"
                v-model="against_uuid" :object="false" id="against_uuid_multiselect" :closeOnSelect="true" :closeOnDeselect="true"
                :options="user_profile.filter_presets" :class="{'active' : against_uuid}"
            >
            </Multiselect>
            <div>
              <div class="sidebar-title">Since:</div>
              <input id="pub-date" class="multiselect d-block datepicker-custom" v-model="modals.coi_details.start_year" min="2000" max="2024" type="number" step="1"/>
            </div>
          </div>
          <div class="col-6 col-xs-12">
            <div class="mt-4">
                <div class="d-flex flex-column">
                  <div class="slider" role="button" 
                      :class="active_methods.includes('coauthorships')?'active':'inactive'"
                      @click="toggle_method('coauthorships')">
                      <h5 class="slider-header me-2">Co-authorships</h5>
                      <div class="slider-space">
                          <span class="slider-toggle"></span>
                      </div>
                  </div>
                  <div v-if="active_methods.includes('coauthorships')">
                      <Multiselect
                          mode="tags" label="name" valueProp="id" :searchable="true" placeholder="Only these publication types" 
                          :attrs='{"autocomplete": "one-time-code"}'
                          v-model="crossref_types" :object="false" id="source_type_multiselect" :closeOnSelect="false" :closeOnDeselect="true"
                          :options="crossref_options" class='active mt-2'
                      >
                      </Multiselect>
                    <label class="checkbox-inline d-flex align-items-center mt-2" for="author_position_toggle">
                          <input id="author_position_toggle" class="me-1" v-model="author_position" type="checkbox"/>
                          Check only first 2 and last 2 authors 
                    </label>
                  </div>
                  <div class="slider mt-4" role="button" 
                      :class="active_methods.includes('coaffiliations')?'active':'inactive'"
                      @click="toggle_method('coaffiliations')">
                      <h5 class="slider-header me-2 ">Historical Affiliations</h5>
                      <div class="slider-space">
                          <span class="slider-toggle"></span>
                      </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="col-12">
            <div class="my-4">
              <span class="btn-primary me-2" id="check_conflicts_btn" @click="get_coi_information()">Check</span>
              <span v-if="Object.keys(coauthorships).length" class="btn-primary" @click="excel_export()"> <i class="fa fa-file-excel"></i> Excel Export</span>
            </div>
          </div>
        </div>
      <div v-if="this.show.loading" class="row">
          <Spinner/>
      </div>
      </div>
      <div class="container-fluid mw-100">
        <div class="row" v-if="this.show.results">
          <hr/>
          <table id="coi_results" class="my-4">
            <thead>
              <th></th>
              <th class="px-1 text-center" 
                  :key="'check'+header_expert.identifier" 
                  v-for="header_expert in active_against_preset.conflict_of_interest.records"
                  @mouseenter="set_hover(false, header_expert.identifier)"
                  @mouseleave="set_hover(false,false)"
                  :class="{'hover_column': this.hover.column==header_expert.identifier}"
                >
                  {{header_expert.display_name}}
                </th>
            </thead>
            <tbody>
              <tr class="text-center"
                  :key="'against'+row_expert.identifier"
                  v-for="row_expert in active_check_preset.conflict_of_interest.records"
                  @mouseenter="set_hover(row_expert.identifier, false)"
                  @mouseleave="set_hover(false,false)"
                  :class="{'hover_row': this.hover.row==row_expert.identifier}"
                >
                <td>{{row_expert.display_name}}</td>
                <td class="coi_cell"
                    :key="'intersect'+row_expert+'-'+column_expert"
                    :class="{'hover_column': this.hover.column==column_expert.identifier}"
                    v-for="column_expert in active_against_preset.conflict_of_interest.records" 
                    @mouseenter="set_hover(row_expert.identifier, column_expert.identifier)"
                    @mouseleave="set_hover(false,false)"
                    @click="show_coi_details(row_expert, column_expert)"
                  >
                  <div v-if="row_expert.identifier == column_expert.identifier">
                    <span class="badge bg-warning">Overlap</span>
                  </div>
                  <div v-else>
                    <span class="badge bg-info me-2" v-if="coauthorships[row_expert.identifier][column_expert.identifier]"> {{coauthorships[row_expert.identifier][column_expert.identifier].evidence.length}} Publications</span>
                    <span class="badge bg-success" v-if="check_institution_intersect(row_expert.identifier, column_expert.identifier).length"> {{check_institution_intersect(row_expert.identifier, column_expert.identifier).length}} Institutions</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ModalContainer/>
    </div>
  </div>
</template>

<script>
//dependencies
import navbar from '../components/Navbar.vue'
import shared from '../components/shared'
import Spinner from '../components/Spinner.vue';
import ModalContainer from '../components/Modals/ModalContainer.vue'
import Multiselect from '@vueform/multiselect'
import * as XLSX from 'xlsx'
import axios from 'axios'

import {mapState} from 'vuex'

export default {
  name: 'CoiChecker',
  components: {navbar, ModalContainer, Multiselect, Spinner},
  computed: {
    ...mapState(['user_profile', 'modals']),
    colorMap: shared.colorMap,
    active_check_preset: function() {
      return this.user_profile.filter_presets.find(preset => preset.uuid == this.check_uuid)
    },
    check_ids: function() {
      if (!this.active_check_preset) return []
      // recast legacy format:
      if (this.active_check_preset.conflict_of_interest.check) {
        this.active_check_preset.conflict_of_interest.records = this.active_check_preset.conflict_of_interest.check.map(function(record) {
          record.identifier = record.id
          return record
        })
      }
      return this.active_check_preset.conflict_of_interest.records.map(item => {return item.identifier})
    },
    against_ids: function() {
      // recast legacy format:
      if (this.active_against_preset.conflict_of_interest.check) {
        this.active_against_preset.conflict_of_interest.records = this.active_against_preset.conflict_of_interest.check.map(function(record) {
          record.identifier = record.id
          return record
        })
      }
      return this.active_against_preset.conflict_of_interest.records.map(item => {return item.identifier})
    },
    active_against_preset: function() {
      return this.user_profile.filter_presets.find(preset => preset.uuid == this.against_uuid)
    }
  },
  created: function() {
    this.$store.commit('SET_USER',JSON.parse(document.getElementById('user-data').text));
  },
  data: function() {return(
    {
      check_uuid: false,
      against_uuid: false,
      author_affs: [],
      author_position: 0,
      institutions: {},
      coauthorships: {},
      active_queries:[],
      show: {
        loading:0,
        results: 0,
      },
      crossref_types: [
        "journal-article"
      ],
      crossref_options: [
        {name: "Journal Articles", id: "journal-article"},
        {name: "Proceedings", id: "proceedings"},
        {name: "Preprints and posted content", id: "posted-content"},
        {name: "Database", id: "database"},
      ],
      active_methods: ['coauthorships', 'coaffiliations'],
      hover: {
        row: false,
        column:false,
      },
      user: {
        searches: []
    }
  })},
  methods: {
      toggle_method(toggle_method) {
        this.active_methods = _.xor(this.active_methods, [toggle_method])
      },
      check_coauthor_conflicts_for_publication(publication) {
        let _this = this;
        // for publication we get the author ids
        let author_ids = _.map(publication.authorships, function(authorship) {return authorship.author.id})
        if (_this.author_position) {
          author_ids = [...new Set(author_ids.slice(0, 2).concat(author_ids.slice(author_ids.length-2)))]; 
        }
        // compare with check list
        this.check_ids.forEach(function(check_id) {
            // if the checked ID is an author of this paper
            if(author_ids.includes(check_id)) {
            _this.against_ids.forEach(against_id => {
              // and an against author is to
               if(author_ids.includes(against_id)) {
                // we add this paper to the intersection, checking if the intersection exists
                if(_this.coauthorships[check_id][against_id]) {
                  _this.coauthorships[check_id][against_id]['evidence'].push(publication)
                }
                // or adding that intersection
                else {
                  _this.coauthorships[check_id][against_id] = {evidence: [publication]}
                }
              }
            }
            )
          }
        })
      },
      parse_coaffiliations: function(authors) {
        let _this = this;
        function check_recent_affiliation(element) {
          return element > _this.modals.coi_details.start_year;
        }
        // for every author, check if affiliations are recent
        authors.forEach(function(author) {
          author.affiliations.forEach(aff => {
            if (aff.years.some(check_recent_affiliation)) {
              // update institution info
              _this.institutions[aff.institution.id] = aff.institution
              // if the author has no author_affiliation object entry yet, create it
              if (!_this.author_affs[author.id]) {
                _this.author_affs[author.id] = {}
              }
              // then add the relevant years.
              _this.author_affs[author.id][aff.institution.id] = aff.years
            }
          })
        })
      },
      check_institution_intersect: function(auth_1, auth_2) {
        let _this = this;
        if(!_this.author_affs[auth_1] || !_this.author_affs[auth_2]) return false
        return  _.intersection(Object.keys(_this.author_affs[auth_1]), Object.keys(_this.author_affs[auth_2]))
      },
      set_hover: function(row_expert_id, column_expert_id) {
        this.hover.row = row_expert_id;
        this.hover.column = column_expert_id;
      },
      show_coi_details: function(row_expert, column_expert) {
        let _this = this;
        this.$store.state.modals.coi_details['check'] = row_expert
        this.$store.state.modals.coi_details['against'] = column_expert
        if(this.active_methods.includes('coauthorships')) {
          this.$store.state.modals.coi_details['works'] = _this.coauthorships[row_expert.identifier][column_expert.identifier]?.evidence??[]
        }
        else {
          this.$store.state.modals.coi_details['works'] = []
        }
        if(this.active_methods.includes('coaffiliations')) {
          this.$store.state.modals.coi_details['institutions'] = _this.institutions
          this.$store.state.modals.coi_details['affiliation_years'] = [_this.author_affs[row_expert.identifier]??[], _this.author_affs[column_expert.identifier]??[]]
        }
        else {
          this.$store.state.modals.coi_details['institutions'] = []
          this.$store.state.modals.coi_details['affiliation_years'] = [[],[]]
        }
        this.$store.dispatch('create_coi_details_modal')
      },
      get_coi_information: async function() {
        let _this = this;
        if(!this.check_uuid || !this.against_uuid) {
          alert('Both the column and row fields need to be present.')
          return false
        }
        if(!this.active_methods.length) {
          alert('You need to select at least one method of conflict of interest check.')
          return false
        }
        this.show.results = 0
        this.show.loading = 1
        let async_calls = []
        // reset coauthorships to empty objects for all check authors
        _this.check_ids.forEach(function(check_id) {
          _this.coauthorships[check_id] = {}
        })
        this.author_affs = {}
        // getting coauthored publications
        if(this.active_methods.includes('coauthorships')) {
          async_calls.push(this.$axiosQ.jumpQueue('openalex', {
            method: "get",
            url: "https://api.openalex.org/works",
            params: {
              filter: `type_crossref:${_this.crossref_types.join('|')},publication_year:>${_this.modals.coi_details.start_year},author.id:${_this.check_ids.join('|')},author.id:${_this.against_ids.join('|')}`,
              sort: 'publication_date:desc',
              per_page: 200,
            }
          }).then(response => {
            if(response.data.results.length == 200) {
              alert('More than 200 publications were found with authors from both lists. Because only the first 200 results are displayed, the table could be missing conflicts.'+
              'Please use a stricter date filter, or remove some authors from the check.')
            }
            if(!response.data.results.length) {
              alert('No publications were found with these filters.')
            }
            response.data.results.forEach(publication => _this.check_coauthor_conflicts_for_publication(publication))
            this.show_results = 1
          }))
        }

        if(this.active_methods.includes('coaffiliations')) {
          // getting all author information (includes their affiliation history)
          async_calls.push(this.$axiosQ.jumpQueue('openalex', {
            method: "get",
            url: "https://api.openalex.org/authors",
            params: {
              filter: `ids.openalex:${_this.check_ids.join('|')}|${_this.against_ids.join('|')}`,
              per_page: 200,
            }
          }).then(response => {
            console.log(response.data)
            this.parse_coaffiliations(response.data.results)
          }))
          // wait for all calls to finish completely, then change display data.
        }
        await axios.all(async_calls).then(() => {
            _this.show.loading = 0
            _this.show.results = 1
        })
      },
      getColorStyling: function() {
        return shared.colorMap(this.user_profile.organisation.name)
      },
      excel_export: function() {
        let _this = this;
        var workbook = XLSX.utils.book_new()
        try {
          const ws = _this.create_worksheet()
          XLSX.utils.book_append_sheet(workbook , ws,  'conflicts');
        }
        catch(err) {
          console.log('Unable to generate output for type:' + err)
        }
        XLSX.writeFile(workbook, "GlobalCampus CoI details "+ new Date().toISOString() + ".xlsx");
      },
      create_worksheet:function() {
        let _this = this;
        let coi_details = []
        // for every row, we add something to our JSON dictionary of conflicts.
        _.map(_this.active_check_preset.conflict_of_interest.records, function(row_expert, key) {
            let row_conflicts = {}
            // first, the header column
            row_conflicts['Author_name'] = row_expert.display_name,
            // then, for every column author (in the Against list),
            // we add Pub and Inst data to the cells.
            _.each(_this.active_against_preset.conflict_of_interest.records, function(column_expert) {
              let coi_description = ''
              // if copubs, add count
              if(row_expert.identifier == column_expert.identifier) {
                coi_description += "Overlap"
              }
              else {
                if(_this.coauthorships[row_expert.identifier][column_expert.identifier]) {
                  coi_description += _this.coauthorships[row_expert.identifier][column_expert.identifier].evidence.length + ' Publications: \r\n';
                  _this.coauthorships[row_expert.identifier][column_expert.identifier].evidence.forEach(publication => {
                    coi_description += publication.id +'\r\n'
                  })
                }
                // if co-institutions, add count:
                let institution_intersection = _this.check_institution_intersect(row_expert.identifier, column_expert.identifier)
                if(institution_intersection.length) {
                  coi_description += institution_intersection.length + ' Institutions: \r\n';
                  coi_description += institution_intersection.join('\r\n')
                }
              }
              
              row_conflicts[column_expert.display_name] = coi_description
            })
            coi_details.push(row_conflicts)
        });
        const ws = XLSX.utils.json_to_sheet(coi_details);
        return ws
      }
    },
}
</script>

<style scoped>
  .home {
    height: 100vh;
    background-color:var(--background-color);
    width:100vw;
    overflow-y: hidden;
  }
  .no-decoration {
    text-decoration:none;
  }
  .query-link {
    color:initial;
  }
  .hover-orange:hover  {
    color:var(--orange_highlight);
  }
  #app-content{
      display:flex;
      width:100vw;
      min-width:800px;
      margin:auto;
      height:calc(100vh - 40px);
      overflow-y:auto;
  }
  #collections-index {
    margin: 0 auto;
    max-width: 1920px;
    width: 100vw;
  }
  a, .active {
    color: var(--primary_color);
  }
  #coi_results {
    table-layout:fixed;
    background-color:transparent!important;
  }
  .coi_cell:hover {
    cursor:pointer;
  }
  .hover_column, .hover_row {
    background-color: var(--primary_color_30);
  }

  .slider {
    justify-content:flex-start;
    align-items:baseline;
  }
  .slider.active .slider-header{ 
      color: var(--primary_color)!important;
  }
  .slider.active .slider-toggle {
    margin-left:14px;
    background: var(--primary_color);
  }
  .slider.inactive .slider-toggle {
    margin-left:1px;
    background:white;
  }
</style>