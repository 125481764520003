<template>
    <div class="graphcontainer">
      <div class="canvas-container">
          <div class="graph-interaction d-flex align-items-start">
            <div class="zoom-icons" role="button">
              <span @click="reset_zoom" id="restore_zoom" title="Restore zoom"><i class="custom-icon custom-reset-zoom"></i></span>
            </div>
          </div>
        <div @click.stop="" id="network_graph" ref="network_graph" class="d-flex justify-content-around">
        </div>
        <div id='loading' v-show="show.graph_loading">
          <Spinner/>
        </div>
        <div id="popper_anchor"></div>
        <Teleport to="#hovering_author_container">
          <div id="overlay" @click="show.graph_author_hover=0"></div>
          <div>
              <CloseButton @close="show.graph_author_hover=0" title="Close"/>
              <Expert v-if="show.graph_author_hover" :item="detailed_author" in_graph="1"/>
          </div>
        </Teleport>
      </div>
      <div id="concept-area" class="ms-2">
        <span class="btn-primary me-2 mb-1" 
              :class="{
                hover: hovered_concepts.indexOf(concept.display_name)>-1, 
                active: active_concepts.indexOf(concept.id)>-1
              }"
              @mouseover="highlight_concept(concept.id)" 
              @click="toggle_concept(concept)" 
              @mouseleave="reset_highlights()" 
              :key="concept.id" 
              v-for="concept in Object.fromEntries(Object.entries(available_concepts).slice(0,15))">
                {{remove_parenthesis_text(concept.display_name)}}
          </span>
      </div>
  </div>
</template>

<script>
import shared from "./shared.js"
import G6 from '@antv/g6';
import Expert from './Expert/Expert.vue'
import CloseButton from './Utils/CloseButton.vue';
import Spinner from './Spinner.vue';
import { createPopper } from '@popperjs/core';
import { mapState } from 'vuex';

export default {
  name: "Graph",
  components: {Expert, CloseButton, Spinner},
  computed: {
    ...mapState(['show', 'searchbox_collection']),
    graph_data: function() {
      return this.$store.state.graph_data
    },
    g6_graph: {
      get() {
        return this.$store.state.g6_graph;
      },
      set(v) {
        this.$store.commit('UPDATE_G6_OBJECT', v);
      } 
    },
    g6_configuration() {
      let _this = this  
      return {
      container: 'network_graph', // String | HTMLElement, required, the id of DOM element or an HTML node
        animate: true,
        fitCenter: true,
        // old
        layout: {
          type: 'gForce',
          center: [400,600],
          preventOverlap: 'true',
          coulombDisScale: this.settings.coulomb,
          nodeSpacing: this.settings.node_spacing,
          linkDistance: this.settings.link_distance,
          gravity: this.settings.gravity,
          gpuEnabled: false,
          edgeStrength: function(edge) {
            if (edge.count) {
              return edge.count * _this.settings.pub_count_attraction_factor
            }
            if (edge.direction=='attract') { return _this.settings.concept_attraction_factor * edge.weight}
            if (edge.direction=='repulse') { return _this.settings.concept_repulsion}
            return _this.settings.default_edge_strength
          },
          onLayoutEnd: () => {
            console.log('force layout done')
            this.check_and_highlight_searchbox_item()
            this.set_loading(0)
          }
        },
        defaultEdge: {
          type: 'line',
          labelCfg: {
            'visible': false,
          }
        },
        defaultNode: {
          size: 20, // The size of nodes
          type: 'circle',
          style: {
            cursor: 'pointer',
            fill: this.colors.secondary,
            stroke: this.colors.greyed_out,
          },
          labelCfg: {
            position: 'right',
            style: {
              background: {
                fill: '#ffffffbb',
                radius: 2,
              },
              cursor: 'pointer',
              fill: '#000', // The color of the text
              fontFamily: 'BaiJamburee',
              stroke: 'white',
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowColor: 'rgba(0,0,0,.2)',
              shadowBlur: 3,
            },
          },
        },
        nodeStateStyles: {
          'hover': {
            fill: this.colors.highlight,
            cursor: 'default'
          },
          'searchbox': {
            fill: '#ffb980',
            stroke: this.colors.highlight,
          },
          'original': {
            cursor: 'pointer',
            fill: this.colors.secondary,
            stroke: this.colors.greyed_out,
          },
          'new': {
            lineWidth: 3,
            fill: this.colors.secondary,
            stroke: this.colors.primary,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowColor: 'rgba(156,156,156,.4)',
            shadowBlur: 25,
          },
          'related': {
            'fill': this.colors.Author,
          },
          'highlight': {
            'fill': this.colors.highlight,
            'stroke': 'transparent',
          },
        },
        edgeStateStyles: {
          'related': {
            lineWidth:3,
            stroke: this.colors.Author,
          }
        },
        plugins: [this.tooltip],
        modes: {
          default: [
            {
              type: 'drag-canvas', 
            },
            {
              type: 'zoom-canvas',
            }
            ,
            ], // Allow users to drag canvas, zoom canvas, and drag nodes
        },
      }
    },
    hovered_concepts() {
      if (!this.hovering) return []
      return _.map(this.hovering['fields']['x_concepts'], 'display_name')
    },
    active_concepts() {
      let active_concepts = []
      if (this.g6_graph.getNodes) {
        this.g6_graph.getNodes().map((node) => {
          if (node['_cfg']['id'].slice(0,1)=='C') active_concepts.push(node['_cfg']['id'])
        })
      }
      return active_concepts
    },
    available_concepts() {
      let concepts = {}
      let authors = this.$store.getters.getCurrentResultList
      _.map(authors, function(author) {
          if(!author.fields.id) {return};
          _.each(author.fields.x_concepts, function(concept) {
            concept.id = concept.id.replace('https://openalex.org/', '')
            if (concept.level < 2) return true;
            if (!concepts[concept.id]) {
              concepts[concept.id] = concept
              concepts[concept.id]['author_weight'] = {}
            }
            concepts[concept.id]['author_weight'][author.fields.id] = concept.score
            concepts[concept.id]['count'] = Object.keys(concepts[concept.id]['author_weight']).length
          })
      })
      let shared_concepts = _.filter(concepts, function(concept) {return concept['count'] > 1 });
      return _.keyBy(_.orderBy(shared_concepts, 'count', 'desc'), 'id')
    },
    tooltip() {
        let tooltip = 
          new G6.Tooltip({
            offsetX: 20,
            offsetY: -8,
            getContent(evt) {
              let click_target = evt.target.cfg.name
              const outDiv = document.createElement('div')        
              if (click_target === 'hex-bottom' || click_target === 'icon-bottom') {
                outDiv.innerHTML = '<span class="h6 mb-0">Show profile of '+evt.item['_cfg'].model.label+'</span>'
              }
              else if (click_target === 'hex-top' || click_target === 'icon-top') {
                outDiv.innerHTML = '<span class="h6 mb-0">Add authors who publish with '+evt.item['_cfg'].model.label+'</span>'
              }
              else {
                outDiv.innerHTML = '<span class="h6 mb-0">'+evt.item['_cfg'].model.label+'</span>'
              }
              return outDiv
          },
          itemTypes: ['node']
      })
      return tooltip
    }
  },
  created:function() {
    _.forEach(this.svg, function(img_obj, img_name) {
      console.log(img_obj)
      if (!img_obj.data_url) {
        img_obj.svg_blob = new Blob([img_obj.raw_svg], {type: 'image/svg+xml'});
        img_obj.data_url = URL.createObjectURL(img_obj.svg_blob);
      }
    })
  },
  data: function(){ return({
    settings: {
      concept_repulsion: 35,
      concept_attraction_factor: 100,
      pub_count_attraction_factor: 10,
      default_edge_strength: 75,
      gravity: 10,
      link_distance: 1,
      coulomb: .006,
    },
    svg: {
      add_authors_icon: {
        svg_blob: false,
        data_url: false,
        raw_svg: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><defs><style>.cls-1{fill:white;}</style></defs><path class="cls-1" d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>`,
      },
      author_info_icon: {
        svg_blob: false,
        data_url: false,
        raw_svg: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><defs><style>.cls-1{fill:white;}</style></defs><path class="cls-1" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm80 256h64c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm256-32H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>`
      },
    },
    max_concept_weight: 1,
    last_active_node: false,
    hovered_node_neighbors: [],
    hovering: false,
    author_infobox: {},
    colors:{
      Author: shared.colorMap()['--primary_color'],
      primary: shared.colorMap()['--primary_color'],
      secondary: shared.colorMap()['--secondary_color'],
      highlight: shared.colorMap()['--orange_highlight'],
      greyed_out: shared.colorMap()['--grey_divider'],
    }
    
  })},
  methods: {
    check_and_highlight_searchbox_item() {
      if (this.searchbox_collection.records[0].record_type == 'author'){
        let searchbox_author_id = this.searchbox_collection.records[0].identifier 
        let author_node = this.g6_graph.findById(searchbox_author_id)
        if (author_node) {
          author_node.toFront()
          author_node.clearStates('new')
          author_node.setState('searchbox', true)
        }
      }
    },
    remove_parenthesis_text: function(text) {
      return text.replace(/ *\([^)]*\) */g, "");
    },
    toggle_concept: function(concept) {
      if (this.active_concepts.includes(concept.id)) {
        this.remove_concept(concept);
      }
      else this.add_concept(concept.id);
    },
    remove_concept: function(concept) {
      let node = this.g6_graph.findById(concept.id)
      node.getEdges().forEach(edge => {
        this.g6_graph.removeItem(edge)
      })
      this.g6_graph.removeItem(node)
      this.g6_graph.updateLayout()
    },
    add_concept: function(concept_id) {
      let _this = this;
      this.set_loading(1)
      let concept = this.available_concepts[concept_id] 
      let x_coords = []
      let y_coords = []
      // determine center for authors for initial positioning
      if (!this.active_concepts.includes(concept_id)) {
        _.each(concept.author_weight, function(weight, author) {
          let author_node = _this.g6_graph.findById(author)
          if (!author_node) return true;
          let author_model = author_node.getModel()
          x_coords.push(author_model.x)
          y_coords.push(author_model.y)
        })
        var new_concept_node = this.g6_graph.addItem('node', {
            id: concept_id,
            x: _.mean(x_coords),
            y: _.mean(y_coords),
            label: this.remove_parenthesis_text(concept.display_name),
            size: 120,
            labelCfg: {
              position: 'center'
            },
            style: {
              'stroke' : 'transparent',
              'fill': '#C1E9E6',
            }
          }
        )
        new_concept_node.toBack()
      }
      // adding attraction for authors that have concept
      _.each(concept.author_weight, function(weight, author) {
        if(!_this.g6_graph.findById(concept_id+'-'+author)){
          _this.g6_graph.addItem('edge', {
            id: concept_id+'-'+author,
            source: concept_id,
            target: _this.g6_graph.findById(author),
            weight: weight,
            direction: 'attract',
            label: '',
            style: {
              opacity: 0
            }
          })
        }
      })
      // adding repulsion to all others
      _.each(_this.g6_graph.getNodes(), function(node, idx) {
        let author = node['_cfg']['id']
        if (concept.author_weight[author] || 
            author.slice(0,22) !== 'https://openalex.org/A' ||
            _this.g6_graph.findById(concept_id+'-'+author)
          ) {
             return true
            }
        else {
          _this.g6_graph.addItem('edge', {
            id: concept_id+'-'+author,
            source: concept_id,
            target: _this.g6_graph.findById(author),
            direction: 'repulse',
            label: '',
            style: {
              cursor:'grab',
              opacity: 0,
              lineWidth:0,
            }
          })
        }
      })
      _this.g6_graph.updateLayout()
    },
    highlight_concept: function(concept_id) {
      let _this = this;
      const concept = this.available_concepts[concept_id]
      const max_concept_weight = _.max(Object.values(concept.author_weight))
      _.each(concept.author_weight, function(weight, author) {
        try {
          let author_node = _this.g6_graph.findById(author)
          const model = author_node.getModel()
          author_node.setState('highlight', true)
          model.size = 30 * weight / max_concept_weight
          author_node.update(model)
        }
        catch (e) {
          console.log('error ' + e)
        }
      })
    },
    reset_highlights: function() {
      let g6nodes = this.g6_graph.findAllByState('node', 'highlight');
      let _this = this;
      g6nodes.forEach(function(g6node) {
        g6node.setState('highlight', false)
        const model = g6node.getModel()
        let original_data = _this.graph_data['nodes'].filter(node => node.id == model['id'])[0]
        try {
          model['size'] = _this.degrees_to_size(original_data)
        }
        catch (e) {
          console.log('Could not reset size for an item: '+ model.id + JSON.stringify(original_data))
        }
        g6node.update(model)
      })
      // restore SearchBox item
      this.check_and_highlight_searchbox_item()
    },
    degrees_to_size: function(node) {
      return 5 + (30*node.degrees/this.$store.state.graph_data.max_degrees)
    },
    update_concepts() {
      let _this = this;
      _.forEach(_this.active_concepts, function(concept_id) {
        _this.add_concept(concept_id)
      })
    },
    upsert_nodes(new_data) {
      let _this = this
      new_data['nodes'].forEach((node) => {
        var nodeItem = {
          'type': 'author',
          'id': node.id,
          'size': _this.degrees_to_size(node),
          'label': node.label??this.$store.getters.getGraphNodesByID[node.id]?.fields.display_name,
        }
        const existing_node = this.g6_graph.findById(node.id);
        if(existing_node) {
          existing_node.clearStates('new')
        }
        else {
          const added_node_item = _this.g6_graph.addItem('node', nodeItem)
          added_node_item.setState('new', true)
        }
      })
    },
    upsert_edges(new_data) {
      let _this = this;
      new_data['relationships'].forEach((edge) => {
        edge.id = edge.source+'-'+edge.target
        let existing_edge = this.g6_graph.findById(edge.id) 
        edge.shape = 'can-running'
        if(existing_edge) {
          console.log('edge already exists')
        }
        else {
          _this.g6_graph.addItem('edge', edge)
        }
      })
    },
    set_loading(value) {
      this.show.graph_loading = value;
    },
    reset_zoom: function() {
      var nodes = this.g6_graph.getNodes()
      this.g6_graph.focusItems(nodes, true, true)
    },
    reset_related: function() {
      this.g6_graph.findAllByState('node', 'related').forEach(item => {
          item.clearStates(['related'])
      })
      this.g6_graph.findAllByState('edge', 'related').forEach(item => {
          item.clearStates(['related'])
      })
    },
    show_author_tooltip: function(evt) {
      let popper_anchor = document.getElementById('popper_anchor')
      popper_anchor.style.top = evt.canvasY+'px'
      popper_anchor.style.left = evt.canvasX+'px'
      let author_container = document.getElementById('hovering_author_container')
      this.author_infobox = createPopper(popper_anchor, author_container, {
        position: 'auto',
      })     
      let opened_id = evt.item['_cfg']['id']
      this.detailed_author = this.$store.getters.getCurrentResultListByID[opened_id];
      this.show.graph_author_hover = 1
    },
    set_hovered_node: function(evt) {
      const { item } = evt
      const item_id = item._cfg.id
      if (!item) return;
      if (item_id.slice(0,1) == 'C') {
        this.highlight_concept(item_id)
        return;
      }
      this.hovering = this.$store.getters.getCurrentResultListByID[item_id]
      this.reset_related()
      item.toFront();
      item.getNeighbors().forEach(node => {
        if (node['_cfg'].id.slice(0,1) !== 'C') {
          node.setState('related', true)
        }
      })
      item.getEdges().forEach(edge => {
        edge.setState('related', true)
      })
      item.setState('hover', true);
      this.g6_graph.get('canvas').get('el').style.cursor = 'pointer'
    },
    reset_hover_states: function() {
      let hovered_items = this.g6_graph.findAllByState('node', 'hover');
      hovered_items.forEach(item => {
        item.setState('hover', false)
      })
      this.reset_highlights()
      this.hovering = false;
      this.g6_graph.get('canvas').get('el').style.cursor = 'grab'
    },
    handle_click: function(evt) {
        let click_target = evt.target.cfg.name
        if (click_target === 'hex-bottom' || click_target === 'icon-bottom') {
          this.show_author_tooltip(evt)
          this.$nextTick(() => this.author_infobox.update())
        }
        if (click_target === 'hex-top' || click_target === 'icon-top') {
          this.set_loading(1)
          this.$store.dispatch('expand_graph', evt.item['_cfg']['id'])
        }
        if (click_target === 'author-keyShape' || click_target === 'text-shape') {
          this.reset_hover_states()
          this.set_hovered_node(evt)
        }
        this.g6_graph.refresh()
    },
    set_button_highlight:function(event) {
      let mousing_over = event.target.cfg.name
      let item = event.item
      // if not hovering over button than return
      if (mousing_over == 'author-keyShape' || mousing_over === 'text-shape' || item._cfg.id.slice(0,1) !== 'A') return true;
      if (mousing_over == 'hex-top' || mousing_over == 'icon-top') {
        var polygon = item._cfg.group.getChildren().filter(item => {return item.cfg.name == 'hex-top'})[0]
        if (polygon.attrs.fill == '#A0DCD9') return true
      }
      else if (mousing_over == 'hex-bottom' || mousing_over == 'icon-bottom') {
        var polygon = item._cfg.group.getChildren().filter(item => {return item.cfg.name == 'hex-bottom'})[0]
        if (polygon.attrs.fill == '#C1E9E6') return true
      }
      //reset background to original
      item._cfg.group.getChildren()[0].attr({'stroke': 'transparent', 'fill': '#A0DCD9'})
      item._cfg.group.getChildren()[1].attr({'stroke': 'transparent', 'fill': '#C1E9E6'})
      //set background to hoverstate
      polygon.attr(item['_cfg']['styles']['highlight'])
    },
    define_graph: function() {
      let _this = this;
      G6.registerNode('author', {
        setState(name, value, item) {
          if (name=='hover' && value) {
            item.get('keyShape').attr(item['_cfg']['styles']['hover'])
            // this.updateShape(item['cfg'], item, , false, 'node_only')
            const group = item.getContainer();
            const size = item.get('keyShape').attrs.r
            const top_or_bottom = [1,-1]
            top_or_bottom.forEach(function(inv) {
              const R = inv*(30+size)
              const r = (0.866*R)
              const points = [
                [ -r, inv],
                [ -r, .5*R],
                [ 0, R],
                [ r, .5*R],
                [ r, inv],
                // ['Z'],
              ]
            const style = Object.assign(
              {},
              {
                points: points,
                fill: R>0?'#A0DCD9':'#C1E9E6',
                stroke: 'transparent',
                cursor: 'pointer',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0,0,0,.6)',
                shadowBlur: 5,
                cursor: 'pointer',
              },
            );
            // add a path as keyShape
            const hex = group.addShape('polygon', {
              zIndex:-2,
              attrs: {
                  ...style,
                },
                draggable: false,
                name: inv<0?'hex-top':'hex-bottom',
              });
              const iconWidth = 20
              const iconY =  R > 0 ? 0.25*R+.5*size : ((.25*R) - 15 - .5*size)
              const iconX   = -0.5*iconWidth 
              const iconImg = new Image()
              if (R < 0) {
                iconImg.src = _this.svg.add_authors_icon.data_url
              }
              else {
                iconImg.src = _this.svg.author_info_icon.data_url
              }
              const icon = group.addShape('image', {
                zIndex:-1, 
                attrs: {
                  cursor: 'pointer',
                  width: iconWidth,
                  height: .8*iconWidth,
                  x: iconX,
                  y: iconY,
                  color: 'white',
                  img: iconImg,
                },
                // must be assigned in G6 3.3 and later versions. it can be any value you want
                name: R<0?'icon-top':'icon-bottom',
              })
            })
            group.sort()
          }
          else if (name=='hover' && !value) {
            item.get('keyShape').attr(item['_cfg']['styles']['original'])
            const group = item.getContainer()
            // icons
            group.cfg.children[3].remove()
            group.cfg.children[2].remove()
            // hexes
            group.cfg.children[1].remove()
            group.cfg.children[0].remove()
          }
          else if (value) {
            item.get('keyShape').attr(item['_cfg']['styles'][name])
          }
          else if (!value) {
            // if current state is being removed, splice it from the states list
            if (item['_cfg']['states'][0] == name) {
              item['_cfg']['states'] = item['_cfg']['states'].splice(0,1)
            }
            // if list is empty, reset original
            if (!item['_cfg']['states'][0] || !item['_cfg']['states'][0].length) {
              item.get('keyShape').attr(item['_cfg']['styles']['original']) 
            }
            else {
              item.get('keyShape').attr(item['_cfg']['styles'][item['_cfg']['states'][0]])
            }
          }
        },
        afterDraw(cfg, group) {
          const size = this.getSize(cfg); // translate to [width, height]
          const circle = group.addShape('circle', {
            attrs: {
              zIndex:1,
              x: 0,
              y: 0,
              size: size[0],
              fill: cfg.color,
              opacity: 1,
            },
          });
        }
      }, 'circle')
    this.g6_graph = new G6.Graph(this.g6_configuration);
    this.g6_graph.on('node:mouseenter', evt => {
      this.set_hovered_node(evt)
    });
    this.g6_graph.on('node:mousemove', evt => {
      this.set_button_highlight(evt)
    })
    this.g6_graph.on('node:mouseleave', evt => {
      _this.reset_hover_states()
      _this.reset_related()
    });
  this.g6_graph.on('node:touchend', evt => {
      if(_this.hovering && evt.item._cfg.id !== _this.hovering.fields.id) {
        _this.reset_hover_states()
        return true
      }
      else {
        _this.handle_click(evt)
      }
    })
    this.g6_graph.on('node:click', evt => {
      _this.handle_click(evt)
    });
    this.g6_graph.on('edge:mouseleave', (evt) => {
      _this.g6_graph.get('canvas').get('el').style.cursor = 'grab'
    });
    },
  },
  watch: {
    graph_data: function(new_data, old_data) {
        this.upsert_nodes(new_data)
        this.upsert_edges(new_data)
        this.update_concepts()
        this.g6_graph.updateLayout()
    },
  },
  mounted: function() {
    this.set_loading(1)
    this.$store.dispatch('get_graph')
    this.define_graph()
    if (typeof window !== 'undefined')
      window.onresize = () => {
        if (!this.g6_graph || this.g6_graph.get('destroyed')) return;
        let graph_container = document.getElementById('network_graph')
        if (!graph_container || !graph_container.scrollWidth || !graph_container.scrollHeight) return;
        this.g6_graph.get('canvas').changeSize(graph_container.clientWidth, graph_container.clientHeight);
    };
    // If the search was initiated with an author, we want to emphasize that author as the graph is mounted.
  }
};
</script>

<style>
.graphcontainer {
  width: 100%;
  max-width:100vw;
  z-index:1;
  overflow:hidden;
  display: flex;
  flex-direction:row;
  flex-grow:1;
}
@media (max-width: 992px) {
  .graphcontainer {
    flex-direction:column;
  }
  .canvas-container {
    margin: 0px .5rem;
    max-width: 95vw!important;
  }
  #network_graph {
    height:calc(100vh - 300px - 120px)!important;
  }
  #concept-area {
    margin-top:.5rem;
    flex-direction:row!important;
    flex-wrap:wrap;
  }
}
.graph-interaction {
  position: absolute;
  top: 30px;
  left:10px;
  z-index:2;
  margin-top:calc(-32px + .5rem);
}
.btn-primary.active {
  /* font-weight:800!important; */
  color:white!important;
  background-color: var(--primary_color)!important;
}
.flip-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.graph-interaction span:hover {
  color:var(--primary_color);
}
#popper_anchor {
  position:absolute;
}
.custom-icon {
  background-color:black;
  position: relative;
  width:1.5rem;
  height: 1.5rem;
  overflow: visible;
  vertical-align: -0.125em;
  overflow:visible;
  display:inline-block;
}
.custom-reset-zoom {
  mask: url("../assets/img/icons/reset-zoom.svg"); 
  mask-size:cover;
  -webkit-mask: url("../assets/img/icons/reset-zoom.svg");
  -webkit-mask-size: cover;
}
.custom-reset-zoom:hover {
  background-color:var(--primary_color);
}
#network_graph {
  border: 1px solid var(--grey_divider);
  background-color:white;
  width:100%;
  height: 100%;
}
#colorpicker {
  color:rgb(156, 156, 156);
}
#concept-area {
  display:flex;
  flex-direction:column;
}
#concept-area .hover {
  background-color: var(--orange_highlight)!important;
  color:white;
}
#loading {
  position:absolute;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
#overlay {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  z-index:3;
}
canvas {
  cursor:grab;
}
.canvas-container {
  position:relative;
  display:flex;
  flex: auto;
  max-width: calc(100vw - 260px - 210px);
  max-height: calc(100vh - 130px - 20px);
}
.g6-component-tooltip {
  background:white!important;
  pointer-events: none;
}
</style>